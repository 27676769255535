export default [
  {
    path: '/notify',
    meta: {
      breadcrumb: `Notify`
    },
    component: () => import('@/components/Base/ParentComponent.vue'),
    children: [
      {
        path: '',
        redirect: { path: '/installation' },
      },
      {
        path: 'custom',
        meta: {
          breadcrumb: `Custom Message`
        },
        component: () => import('@/components/Base/ParentComponent.vue'),
        children: [
          {
            path: '',
            redirect: { path: '/installation' },
          },
          {
            path: ':installationId',
            meta: {
              breadcrumb: routeParams => `From ${ routeParams.installationId }`
            },
            component: () => import('@/components/Base/ParentComponent.vue'),
            children: [
              {
                path: '',
                redirect: { path: '/installation/:installationId/operation' },
              },
              {
                path: ':recipientId',
                props: true,
                component: () => import('@/views/NotifyWizard/CustomNotifyMessage.vue'),
                meta: {
                  breadcrumb: routeParams => `To ${ routeParams.recipientId }`
                },
              }
            ]
          },
        ]
      },
      {
        path: ':installationId',
        meta: {
          breadcrumb: routeParams => `From ${ routeParams.installationId }`
        },
        component: () => import('@/components/Base/ParentComponent.vue'),
        children: [
          {
            path: '',
            redirect: { path: '/installation/:installationId/operation' },
          },
          {
            path: ':recipientId',
            props: true,
            component: () => import('@/views/NotifyWizard/Notify.vue'),
            meta: {
              breadcrumb: routeParams => `To ${ routeParams.recipientId }`
            },
          }
        ]
      }
    ]
  },
  {
    path: '/notify-group',
    meta: {
      breadcrumb: `Notify Group`
    },
    component: () => import('@/components/Base/ParentComponent.vue'),
    children: [
      {
        path: ':installationId',
        props: true,
        meta: {
          breadcrumb: routeParams => `From ${routeParams.installationId}`
        },
        component: () => import('@/views/NotifyWizard/NotifyGroup.vue')
      }
    ]
  }
];