export default [
  {
    path: '/videopostprocessing',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: `Video Postprocessing`
    },
    props: true,
    children: [
      {
        path: '',
        props: true,
        redirect: "/videopostprocessing/theme",
      },
      {
        path: 'cutconfig',
        component: () => import('@/views/VideoPostprocessing/CutConfig.vue'),
        props: true,
        meta: {
          breadcrumb: `Cut Configuration`
        }
      },
      {
        path: 'sounds',
        component: () => import('@/views/VideoPostprocessing/Sounds.vue'),
        props: true,
        meta: {
          breadcrumb: `Sounds`
        }
      },
      {
        path: 'fonts',
        component: () => import('@/views/VideoPostprocessing/Fonts.vue'),
        props: true,
        meta: {
          breadcrumb: `Fonts`
        }
      },
      {
        path: 'collections',
        component: () => import('@/components/Base/ParentComponent.vue'),
        props: true,
        meta: {
          breadcrumb: `Image Collections`
        },
        children: [
          {
            path: '',
            component: () => import('@/views/VideoPostprocessing/ImageCollections.vue'),
            props: true,
          },
          {
            path: ':collectionId',
            component: () => import('@/views/VideoPostprocessing/ImageCollectionElements.vue'),
            props: true,
            meta: {
              breadcrumb: routeParams => `Collection ${ routeParams.collectionId }`
            }
          }
        ]
      },
      {
        path: 'sound',
        component: () => import('@/components/Base/ParentComponent.vue'),
        props: true,
        meta: {
          breadcrumb: `Theme Sound Elements`
        },
        children: [
          {
            path: '',
            component: () => import('@/views/VideoPostprocessing/Theme/SoundOverview.vue'),
            props: true,
          },
          {
            path: ':elementId',
            component: () => import('@/views/VideoPostprocessing/Theme/ThemeElementDetail.vue'),
            props: true,
            meta: {
              breadcrumb: routeParams => `Element ${ routeParams.elementId }`
            }
          }
        ]
      },
      {
        path: 'installationConfig',
        component: () => import('@/views/VideoPostprocessing/InstallationConfig.vue'),
        props: true,
        meta: {
          breadcrumb: `Installation Configuration`
        }
      },
      {
        path: 'theme',
        props: true,
        component: () => import('@/components/Base/ParentComponent.vue'),
        meta: {
          breadcrumb: `Themes`
        },
        children: [
          {
            path: '',
            component: () => import('@/views/VideoPostprocessing/Theme/ThemeOverview.vue'),
            props: true,
          },
          {
            path: ':themeId',
            props: true,
            component: () => import('@/components/Base/ParentComponent.vue'),
            meta: {
              breadcrumb: routeParams => `Theme ${ routeParams.themeId }`
            },
            children: [
              {
                path: '',
                redirect: "/videopostprocessing/theme/:themeId/layoutimageelement",
                props: true,
              },
              {
                path: 'layoutimageelement',
                component: () => import('@/components/Base/ParentComponent.vue'),
                props: true,
                meta: {
                  breadcrumb: `Layout Image Elements`
                },
                children: [
                  {
                    path: '',
                    component: () => import('@/views/VideoPostprocessing/Theme/LayoutImageOverview.vue'),
                    props: true,
                  },
                  {
                    path: ':elementId',
                    component: () => import('@/views/VideoPostprocessing/Theme/ThemeElementDetail.vue'),
                    props: true,
                    meta: {
                      breadcrumb: routeParams => `Element ${ routeParams.elementId }`
                    }
                  }
                ]
              },
              {
                path: 'layouttextelement',
                props: true,
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Layout Text Elements`
                },
                children: [
                  {
                    path: '',
                    props: true,
                    component: () => import('@/views/VideoPostprocessing/Theme/LayoutTextOverview.vue'),
                  },
                  {
                    path: ':elementId',
                    component: () => import('@/views/VideoPostprocessing/Theme/ThemeElementDetail.vue'),
                    props: true,
                    meta: {
                      breadcrumb: routeParams => `Element ${ routeParams.elementId }`
                    }
                  }
                ]
              },
              {
                path: 'userdependenttextelement',
                props: true,
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Userdependent Text Elements`
                },
                children: [
                  {
                    path: '',
                    props: true,
                    component: () => import('@/views/VideoPostprocessing/Theme/UserDependentTextOverview.vue'),
                  },
                  {
                    path: ':elementId',
                    component: () => import('@/views/VideoPostprocessing/Theme/ThemeElementDetail.vue'),
                    props: true,
                    meta: {
                      breadcrumb: routeParams => `Element ${ routeParams.elementId }`
                    }
                  }
                ]
              },
              {
                path: 'userdependentimageelement',
                props: true,
                component: () => import('@/components/Base/ParentComponent.vue'),
                meta: {
                  breadcrumb: `Userdependent Image Elements`
                },
                children: [
                  {
                    path: '',
                    component: () => import('@/views/VideoPostprocessing/Theme/UserDependentImageOverview.vue'),
                    props: true,
                  },
                  {
                    path: ':elementId',
                    component: () => import('@/views/VideoPostprocessing/Theme/ThemeElementDetail.vue'),
                    props: true,
                    meta: {
                      breadcrumb: routeParams => `Element ${ routeParams.elementId }`
                    }
                  }
                ]
              },
              {
                path: 'sound',
                component: () => import('@/components/Base/ParentComponent.vue'),
                props: true,
                meta: {
                  breadcrumb: `Theme Sound Elements`
                },
                children: [
                  {
                    path: '',
                    component: () => import('@/views/VideoPostprocessing/Theme/SoundOverview.vue'),
                    props: true,
                  },
                  {
                    path: ':elementId',
                    component: () => import('@/views/VideoPostprocessing/Theme/ThemeElementDetail.vue'),
                    props: true,
                    meta: {
                      breadcrumb: routeParams => `Element ${ routeParams.elementId }`
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'introoutro',
        props: true,
        component: () => import('@/components/Base/ParentComponent.vue'),
        meta: {
          breadcrumb: `Intros / Outros`
        },
        children: [
          {
            path: '',
            component: () => import('@/views/VideoPostprocessing/IntroOutro/IntroOutroElementOverview.vue'),
            props: true,
          },
          {
            path: ':introOutroId',
            props: true,
            meta: {
              breadcrumb: routeParams => `Intro / Outro ${ routeParams.introOutroId }`
            },
            component: () => import('@/components/Base/ParentComponent.vue'),
            children: [
              {
                path: '',
                redirect: "/videopostprocessing/introoutro/:introOutroId/intro",
                props: true,
              },
              {
                path: 'intro',
                component: () => import('@/views/VideoPostprocessing/IntroOutro/IntroOverview.vue'),
                props: true,
                meta: {
                  breadcrumb: `Intros`
                }
              },
              {
                path: '/videopostprocessing/introoutro/:introOutroId/outro',
                component: () => import('@/views/VideoPostprocessing/IntroOutro/OutroOverview.vue'),
                props: true,
                meta: {
                  breadcrumb: `Outros`
                }
              }
            ]
          }
        ]
      }
    ]
  }
];