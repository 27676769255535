<template>
  <!--
    The default container to put content into.
    Has a title bar with title, subtitle and an icon.
  -->
  <!-- div is needed for the transition to work properly -->
  <div>
    <!-- makes the component fade in -->
    <transition 
      name="fade" 
      mode="out-in" 
      appear
    >
      <!-- portlet start -->
      <div class="m-content portlet">
        <slot name="beforePortlet" />
        <!--Begin::Section-->

        <div class="m-portlet">
          <!-- the head of the portlet: gets an additional class when set -->
          <div :class="portletHeadClass">
            <div class="m-portlet__head-caption">
              <!-- TITLE WRAPPER -->
              <div class="m-portlet__head-title">
                <!-- ICON: gets displayed when icon is available -->
                <span
                  :v-if="icon"
                  class="m-portlet__head-icon"
                >                
                  <font-awesome-icon
                    :icon="iconName"
                    :class="iconColor"
                  />
                </span>
                <!-- TITLE SLOT -->
                <h3 class="m-portlet__head-text">
                  {{ title }} <small v-if="subTitle">{{ subTitle }}</small>
                </h3>
              </div>
            </div>

            <!-- BUTTONS SLOT -->
            <div class="m-portlet__head-tools">
              <slot name="buttons" />
            </div>
          </div>

          <!-- Banner SLOT -->   
          <slot name="afterHeadBorder">
            <div
              v-if="showEnvironmentBanner"
              :style="`text-align: center; color: white; background-color:${ environmentText == 'Prod' ? '#00A0E3' : '#9a9caf' } !important;`"
            >
              {{ environmentText }}
            </div>
          </slot>

          <!-- BODY SLOT -->
          <div :class="portletBodyClass">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Portlet",
  props: {
    portletHeadClass: {
      type: String,
      default: "m-portlet__head"
    },
    portletBodyClass: {
      type: String,
      default: "m-portlet__body"
    },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'question'
    },
    environmentText: {
      type: String,
      default: null
    },
    showEnvironmentBanner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconName () {
      if(this.icon)
      {
        let iconParts = this.icon.split(' ');
        if(iconParts.length == 1) 
        {
          return this.icon;
        }
        else if(iconParts.length == 2)
        {
          return iconParts[0];
        }
      }
      return 'question';
    },
    iconColor () {
      if(this.icon)
      {
        let iconParts = this.icon.split(' ');
        if(iconParts.length == 1) 
        {
          return '';
        }
        else if(iconParts.length == 2)
        {
          return iconParts[1];
        }
      }
      return '';
    },
  }
}
</script>

<style scoped>
.portlet .m-demo__preview.m-demo__preview--badge.float-right {
  padding-top: 1.5rem;
}
.portlet .m-portlet__head-caption {
  min-width: 200px;
  overflow: hidden;
}
.portlet .m-portlet__head {
  height: auto;
  min-height: 4rem;
  flex-wrap: wrap;
}
.m-portlet__head-tools a {
  margin-left: 1rem;
}
.m-portlet__head-tools a:first-of-type {
  margin-left: 0;
}

.m-portlet__body {
  padding: 1rem 1rem !important;
}

.portlet .m-portlet__head.installation-portlet-head {
  padding: 0 1.2rem;
}
</style>