export default [
  {
    path: '/smsmanagement',
    meta: {
      breadcrumb: `Message Management`
    },
    component: () => import('@/components/Base/ParentComponent.vue'),
    children: [
      {
        path: '',
        redirect: { path: '/smsmanagement/template' },
      },
      {
        path: 'incomplete',
        meta: {
          breadcrumb: `Incomplete Templates`
        },
        component: () => import('@/views/SmsManagement/IncompleteTemplateView.vue')
      },
      {
        path: 'template',
        meta: {
          breadcrumb: `Templates`
        },
        component: () => import('@/components/Base/ParentComponent.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/SmsManagement/SmsManagement.vue'),
          },
          {
            path: ':templateId',
            props: true,
            component: () => import('@/views/SmsManagement/MessageOverview.vue'),
            meta: {
              breadcrumb: routeParams => `Template ${ routeParams.templateId }`
            }
          }
        ]
      }
    ]
  }
];