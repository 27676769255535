export default [
  {
    path: '/centraldevicemanagement',
    redirect: '/centraldevicemanagement/searchdevices',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Central Device Management`
    },
    children: [
      {
        path: 'searchdevices',
        component: () => import('@/views/DeviceManagement/DeviceSearchView.vue'),
        meta: {
          breadcrumb: () => `Search Devices`
        },
        children: [
          {
            path: 's/:deviceName',
            props: true,
            component: () => import('@/views/DeviceManagement/DeviceSearchView.vue')
          }
        ]
      },
      {
        path: 'searchdevices/:configuration',
        component: () => import('@/components/Base/ParentComponent.vue'),
        meta: {
          breadcrumb: () => `Search Devices`
        },
        children: [
          {
            path: '',
            props: true,
            component: () => import('@/views/DeviceManagement/DeviceSearchView.vue')
          }
        ]
      },
      {
        path: 'unconfigured',
        component: () => import('@/views/DeviceManagement/UnconfiguredDevices.vue'),
        meta: {
          breadcrumb: () => `Unconfigured Devices`
        }
      },
      {
        path: 'devicereport',
        component: () => import('@/views/DeviceManagement/DeviceReportView.vue'),
        meta: {
          breadcrumb: () => `Device Report`
        }
      },
      {
        path: 'unconfigured-view/:deviceId/:installationId',
        props: true,
        component: () => import('@/components/Device/DeviceDetail/DeviceDetail.vue'),
        meta: {
          breadcrumb: () => `Unconfigured Devices View`
        }
      },
      {
        path: 'bulk-command-list',
        component: () => import('@/views/DeviceManagement/DeviceBulkCommandListView.vue'),
        meta: {
          breadcrumb: () => `Device Bulk Command List`
        }
      },
    ]
  }
];