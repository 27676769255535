import Vue from 'vue';

const eventBus = new Vue();
Vue.use(eventBus);

// makes the eventbus available to all components through this.$eventBus
Object.defineProperties(Vue.prototype, {
    $eventBus: {
      get: function () {
        return eventBus
      }
    }
  })