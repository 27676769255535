import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  })
  return messages;
}

function locale () {
  // get language from localstorage
  let language = localStorage.getItem('alturosSpaLanguage');
  // check if language from localstorage is available
  // if (!language || language.length == 0) {
  //   // reset language to language set in browser
  //   language = (navigator.language || navigator.userLanguage).split('-')[0];
  // }
  return language || 'en';
}

export default new VueI18n({
  locale: locale(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  localeDir: 'locales',
})
