<template>
  <!--
    The view for the InstallationList-component.
    Displays all installations.
  -->
  <Portlet
    :title="$t('installationView.installations')"
    :sub-title="$t('installationView.listOfInstallations')"
    class="installation"
    icon="hdd"
  >
    <template slot="buttons">
      <div v-if="showTrackerFilterValue">
        <div class="form-check form-check-inline">
          <input
            id="trackerV3"
            v-model="trackerFilter"
            class="form-check-input"
            type="checkbox"
            value="trackerV3"
            :disabled="trackerFilter.length == 1 && trackerFilter.includes('trackerV3')"
          >
          <label
            class="form-check-label"
            for="trackerV3"
          >Tracker V3</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            id="trackerV4"
            v-model="trackerFilter"
            class="form-check-input"
            type="checkbox"
            value="trackerV4"
            :disabled="trackerFilter.length == 1 && trackerFilter.includes('trackerV4')"
          >
          <label
            class="form-check-label"
            for="trackerV4"
          >Tracker V4</label>
        </div>
      </div>
      <toggle-button
        v-model="showAll"
        :labels="{ checked: 'show all', unchecked: 'no location' }"
        :width="115"
        :height="30"
        :font-size="12"
        class="m-0"
      />
    </template>
    <InstallationList 
      :show-all="showAll"
      :tracker-filter="trackerFilter"
      :installation-name="installationName"
      :installation-type-query="installationTypeQuery"
      @showTrackerFilter="showTrackerFilter"
    />
  </Portlet>
</template>

<script>
export default {
  name: "Installation",
  components: {
    InstallationList: () => import('@/components/Installation/InstallationList.vue')
  },
  props: {
    installationName: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      showAll: true,
      trackerFilter: null,
      showTrackerFilterValue: false,
      installationTypeQuery: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationView.installations')
    }
  },
  created () {
    if(this.$route.query && this.$route.query.type) {
      this.installationTypeQuery = this.$route.query.type;
    }
  },
  methods: {
    showTrackerFilter (val) {
      this.showTrackerFilterValue = val;
      if(!val) {
        this.trackerFilter = null;
      }
      else {
        this.trackerFilter = ['trackerV3','trackerV4'];
      }
    }
  }
}
</script>
