export default [
  {
    path: '/error',
    component: () => import('@/components/Base/ParentComponent.vue'),
    redirect: '/',
    children: [
      {
        path: ':errorId',
        component: () => import('@/views/ApiError.vue'),
        props: true,
        meta: {
          breadcrumb: routeParams => `Error ${routeParams.errorId}`
        }
      }
    ]
  }
];