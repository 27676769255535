import Vue from 'vue';
const FreshworksWidget = new Vue({
	methods: {
		hide () {
			window.FreshworksWidget('hide');
		},
		show () {
			window.FreshworksWidget('show');
		},
		install (locale) {
	
			this.remove()
			window.fwSettings = {
				'widget_id': 29000000178,
				'locale': locale
			}
			!function () {
				if ("function" != typeof window.FreshworksWidget) {
					var n = function () { n.q.push(arguments) }
					n.q = []
					window.FreshworksWidget = n
				}
			}()

			window.freshdeskScript = document.createElement('script')
			window.freshdeskScript.setAttribute('src', 'https://widget.freshworks.com/widgets/29000000178.js')
			document.head.appendChild(window.freshdeskScript)
		},
		installed () {
			return typeof window.FreshworksWidget == "function"
		},
		identify (email, name) {
			if (!this.installed()) return
			window.FreshworksWidget('identify', 'ticketForm', { email: email, name: name })
		},
		remove () {
			try {
				window.FreshworksWidget('destroy')
				if (!window.freshdeskScript) return
				window.freshdeskScript.parentNode.removeChild(window.freshdeskScript)
				document.getElementById('freshworks-frame').parentNode.removeChild(document.getElementById('freshworks-frame'))
			}
			catch (e) {
				window.FreshworksWidget = undefined
				window.freshdeskScript = undefined
			}
			window.FreshworksWidget = undefined
			window.freshdeskScript = undefined
		},
	},
})
export default FreshworksWidget