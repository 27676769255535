<template>
  <!-- 
    A spinning image that can be displayed while something is loading.
  -->
  <div class="loaderWrapper">
    <div 
      v-if="size !== 'small'"
      class="m-loader m-loader--primary big-loader"
    />
    <div 
      v-if="size === 'small'"
      class="m-loader m-loader--sm m-loader--primary"
    />
  </div>
</template>

<script>
export default {
  name: "LoadingPlaceholder",
  props: {
    size: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>
.loaderWrapper {
  margin: auto;
  text-align: center;
  display: block;
}
.big-loader {
  display: inline-block;
  width: 5rem;
  height: 5rem;
}
.m-loader::before {
  position: relative;
  top: 1rem;
  left: 1rem;
  width: 5rem;
  height: 5rem;
  display: block;
}
</style>
