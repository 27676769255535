export const authenticationMixin = {
  data () {
    return {
      profileOpen: false,
      loggedIn: false,
      user: null
    }
  },
  methods: {
    authenticationLogin () {
      this.$authenticator.signIn();
    },
    authenticationLogout () {
      this.clearCustomerCache().then(() => {
        this.$authenticator.signOut();
      });
    },
    authenticationCheckCredentials () {

      // bad practice - mixins shouldn't change state; here due to legacy reasons
      let user = this.$authenticator.getUser();
     
      if (!user.isSignedIn) {
        //console.log(`isSignedIn: ${user.isSignedIn}`)
        return false;
      }

      if (typeof user.decodedPayload === 'undefined') {
        //console.log("cannot found decodedPayload");
        return false;
      } 
      
      let ressourceAccess = user.decodedPayload.resource_access['onsite-suite'];
      
      if (typeof ressourceAccess !== 'undefined') {
        let validScopeUserRoles = ['scope_customer', 'scope_staff'];
        let validUserRoles = ['admin', 'firstlevel', 'firstlevel_connector', 'zerolevel', 'tracyapp', 'secondlevel', 'projectmanager', 'moderation'];
        let validCredentials = ressourceAccess.roles.some(role => validUserRoles.includes(role)) && ressourceAccess.roles.some(role => validScopeUserRoles.includes(role));
        if (ressourceAccess.roles.includes("scope_staff") && !user.decodedPayload.email.includes('@alturos.com')) {
          this.authenticationLogout();
          return false;
        }
        if(!validCredentials) {
          this.authenticationLogout();
          return false;
        }

        if (user.decodedPayload.exp < (new Date().getTime() + 1) / 1000) {
          this.loggedIn = false;
          //console.log("token is expired");
          return false;
        }

        this.user = user;
        this.loggedIn = validCredentials;
        return true;
      }

      //console.log("ressourceAccess has wrong state");
      return false;
    },
    loadUserFromLocalStorage () {
      return this.$authenticator.loadUser().then((user) => {
        if (user === null || !user.access_token) {
          return null;
        }

        this.$authenticator.setUser(user);
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
        return user;
      });
    },
    checkIsRedirectFromIdentityService () {
      //check if the site is currently called as a callback and let the library parse the state
      if (!window.location.href.includes("state")) {
        return Promise.resolve();
      }

      var manager = this.$authenticator.getManager()
      return manager.signinRedirectCallback().then((user) => {
        this.$authenticator.setUser(user);
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(`signinRedirectCallback: ${err}`);
      });
    },
    authenticationGetUser () {
      return this.$authenticator.getUser();
    },
    authenticationHasRole (requiredRole) {
      if(this.$authenticator) {
        let user = this.$authenticator.getUser();
        let userRoles = user.decodedPayload.resource_access['onsite-suite'].roles;
        return userRoles.includes(requiredRole);
      }
      return false;
    },
    async clearCustomerCache () {
      await this.axios.get(`/installation/ClearCustomerInstallationCache`)
    }
  }
};
