export default [
  {
    path: '/problem',
    component: () => import('@/components/Base/ParentComponent.vue'),
    props: true,
    meta: {
      breadcrumb: `Problems`
    },
    children: [
      {
        path: '',
        component: () => import('@/views/Problem/Problem.vue'),
        props: true,
      },
      {
        path: 'acknowledge',
        component: () => import('@/components/Base/ParentComponent.vue'),
        props: true,
        meta: {
          breadcrumb: `Acknowledge`
        },
        children: [
          {
            path: '',
            redirect: '/problem',
            props: true,
          },
          {
            path: ':triggerId',
            component: () => import('@/components/Base/ParentComponent.vue'),
            props: true,
            meta: {
              breadcrumb: routeParams => `Problem ${ routeParams.triggerId }`
            },
            children: [
              {
                path: '',
                redirect: '/problem',
                props: true,
              },
              {
                path: ':installationId',
                component: () => import('@/components/Base/ParentComponent.vue'),
                props: true,
                meta: {
                  breadcrumb: routeParams => `Of ${ routeParams.installationId }`
                },
                children: [
                  {
                    path: '',
                    redirect: { path: '/installation/:installationId/operation' },
                  },
                  {
                    path: ':source',
                    component: () => import('@/views/Problem/ViewAcknowledgeProblem.vue'),
                    props: true
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        path: 'acknowledgeall',
        component: () => import('@/components/Base/ParentComponent.vue'),
        props: true,
        meta: {
          breadcrumb: `Acknowledge All Problems`
        },
        children: [
          {
            path: '',
            redirect: '/problem',
            props: true,
          },
          {
            path: ':installationId',
            component: () => import('@/components/Base/ParentComponent.vue'),
            props: true,
            meta: {
              breadcrumb: routeParams => `Of ${ routeParams.installationId }`
            },
            children: [
              {
                path: '',
                redirect: { path: '/installation/:installationId/operation' },
                props: true,
              },
              {
                path: ':source',
                component: () => import('@/views/Problem/ViewAcknowledgeProblemAll.vue'),
                props: true
              }
            ]
          }
        ]
      },
      {
        path: 'skimovie',
        component: () => import('@/views/Problem/Skimovie.vue'),
        props: true,
        meta: {
          breadcrumb: `Skimovie Problems`
        }
      },
      {
        path: 'report',
        component: () => import('@/views/Problem/Report.vue'),
        props: true,
        meta: {
          breadcrumb: `Report Problem`
        }
      },
      {
        path: 'mismatchedstates',
        component: () => import('@/views/Problem/MismatchedState.vue'),
        props: true,
        meta: {
          breadcrumb: `Mismatched Installation States`
        }
      },
      {
        path: ':triggerId',
        component: () => import('@/components/Base/ParentComponent.vue'),
        props: true,
        meta: {
          breadcrumb: routeParams => routeParams.triggerId
        },
        children: [
          {
            path: '',
            props: true,
            redirect: '/problem'
          }
        ]
      }
    ]
  }
];