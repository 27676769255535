import Oidc from 'oidc-client';

//Oidc debug settings
//Oidc.Log.logger = console;
//Oidc.Log.level = Oidc.Log.INFO;

class OidcAuthenticator {
  constructor (options) {
    //initial user state
    this.user = {
      isSignedIn: false,
      details: null
    };

    //function that is called when the library loads the user from storage
    /*
    this.onUserLoaded =
      typeof options.onUserLoaded === 'undefined' ? () => {} : options.onUserLoaded;
    */

    //instantiate new UserManager
    this.manager = new Oidc.UserManager({
      userStore: new Oidc.WebStorageStateStore({
        store: window.localStorage
      }),
      stateStore: new Oidc.WebStorageStateStore({
        store: window.sessionStorage
      }),
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      authority: options.authority,
      client_id: options.clientId,
      redirect_uri: options.redirectUri,
      post_logout_redirect_uri: options.redirectUri,
      scope: options.scope,
      response_type: 'id_token token'
    });
  }

  //function that returns a Vue plugin with helper
  getVuePlugin () {

    //bind the OidcAuthenticator's this to another variable (to mitigate shadowing)
    let authenticatorContext = this;

    return {
      //function that is called when Vue.use(xyz) is called
      install (Vue) {

        //add authenticator helper as property to global Vue
        Vue.prototype.$authenticator = {

          //returns user from oidcauthenticator's state
          getManager () {
            return authenticatorContext.manager;
          },

          //returns user from oidcauthenticator's state
          loadUser () {
            return authenticatorContext.manager.getUser();
          },

          //returns user from oidcauthenticator's state
          getUser () {
            return authenticatorContext.user;
          },

          setUser (user) {
            if (!user.access_token) {
              this.signOut()
            }
            authenticatorContext.user = {
              isSignedIn: user !== null,
              details: user,
              decodedPayload: OidcAuthenticator.decodePayload(user.access_token)
            };
          },

          //initiate signin
          signIn () {
            authenticatorContext.manager.signinRedirect().catch(() => {
              //console.log(`Error at login: ${err}`);
            });
          },

          //initiate signout
          signOut () {
            authenticatorContext.manager.signoutRedirect().catch(() => {
              //console.log(`Error at signout: ${err}`);
            });
          },
        };
      }
    }
  }

  //workaround keycloack send the informations wrong
  //helper to decode the JWT token and get the roles
  static decodePayload (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}

export default OidcAuthenticator;
