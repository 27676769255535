<template>
  <!--
    The main vue-app where everything else is put into.
  -->
  <div>
    <LoadingPlaceholder v-if="!isReady" />

    <div
      v-else
      class="appContainer"
    >
      <div
        v-if="loggedIn"
        class="m-grid m-grid--hor m-grid--root m-page"
      >
        <environment />
        <Head />
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
          <Menu />
          <div class="m-grid__item m-grid__item--fluid m-wrapper">
            <router-view />
          </div>
        </div>
      </div>
      <div
        v-else
        class="loginContainer"
      >
        <Login />
      </div>
      <vue-snotify />
    </div>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import FreshdeskWidget from './plugins/FreshdeskWidget.js';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Menu: () => import('@/components/Base/Menu.vue'),
    Head: () => import('@/components/Base/Head.vue'),
    Login: () => import('@/components/Base/Login.vue'),
    Environment: () => import('@/components/Base/Environment.vue')
  },
  mixins: [
    authenticationMixin
  ],
  data () {
    return {
      isReady: false,
    }
  },
  metaInfo () {
    return {
      titleTemplate: this.title,
    }
  },
  computed: {
    title () {
      if (this.store_getEnvironment) {
        return `%s - ${ this.store_getEnvironment.toLowerCase() === "production" ? '' : this.store_getEnvironment } - METIS`;
      }
      return `METIS`;
    },
    ...mapGetters([ 'store_getEnvironment' ])
  },
  watch:{
    $route () {
      this.authenticationCheckCredentials();
    },
    '$i18n.locale' () {
      FreshdeskWidget.install(this.$i18n.locale);
      setTimeout(() => {
         let user = this.authenticationGetUser();
        FreshdeskWidget.identify(user.decodedPayload.email, user.decodedPayload.name);
      }, 2000);
    }
  },  
  beforeMount () {
    this.loadUserFromLocalStorage().then((user) => {

      //If user already available in local storage
      if (user !== null) {
        if (this.authenticationCheckCredentials()) {
          FreshdeskWidget.install(this.$i18n.locale);
          setTimeout(() => {
            let user = this.authenticationGetUser();
            FreshdeskWidget.identify(user.decodedPayload.email, user.decodedPayload.name);
          }, 2000);
          this.$nextTick(() => {
            this.isReady = true;
          });
          return;
        }
      }

      //Check is redirect from Identity Service
      this.checkIsRedirectFromIdentityService().then(() => {
        if(this.authenticationCheckCredentials()) {
          FreshdeskWidget.install(this.$i18n.locale);
          setTimeout(() => {
            let user = this.authenticationGetUser();
            FreshdeskWidget.identify(user.decodedPayload.email, user.decodedPayload.name);
          }, 2000);
        }
        else {
          sessionStorage.setItem('reff', window.location.pathname);
        }
        var sessionItem = sessionStorage.getItem('reff');
        this.$router.push(sessionItem);
        this.$nextTick(() => {
          this.isReady = true;
        });
      });
    });
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.appContainer {
  height: 100%;
}
.loginContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

select.k-textbox {
  width: auto !important;
}
</style>
