// axios (http-requests)
import axios from 'axios';
import VueAxios from 'vue-axios';

import Vue from 'vue';
import router from './router';

// http-requests
Vue.use(VueAxios, axios);

// the axios base url which will be appended to the website-url when sending requests
axios.defaults.baseURL = "/api";
axios.defaults.timeout = 600000; //BP added a 0

// axios interceptors get called when axios sends requests
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {

  // don't do anything if this is just a cancelled request
  if (axios.isCancel(error)) {
    return;
  }

  // timeout
  if (error.code === 'ECONNABORTED' || error.response.status === 408) {
    let errorMessage = generateMessagge(error.config);
    notifyUser(errorMessage.title, errorMessage.description);
    return;
  }

  // don't do anything if we don't have the needed info
  if (!error.response || !error.response.status) {
    return;
  }

  // unauthorized
  if (error.response.status == 401) {
    router.push("/");
    return;
  }

  // forbidden
  if (error.response.status == 403) {
    router.push("/login");
    return;
  }

  // service unavailable
  if (error.response.status == 503) {
    router.push("/error/503");
    return Promise.reject(error);
  }

  if (error.response.status == 400 || error.response.status == 404 || error.response.status == 500) {
    handleError(error);
    return;
  }

  return Promise.reject(error);
});

function handleError (error) {
  let errorMessage = null;
  if (error.response.data) {
    errorMessage = error.response.data;
    let title = error.response.request.statusText ? error.response.request.statusText : 'Error';
    if (errorMessage.description) {
      notifyUser(title, errorMessage.description);
    }
    else {
      notifyUser(title, errorMessage);
    }
  } else {
    errorMessage = generateMessagge(error.response.request);
    notifyUser(errorMessage.title, errorMessage.description);
  }

}

// function isDataValid (data) {
//   if (data == null) {
//     return false;
//   }
//   if (data == undefined) {
//     return false;
//   }
//   if (data.title == null) {
//     return false;
//   }
//   if (data.title == undefined) {
//     return false;
//   }
//   if (data.title.length == 0) {
//     return false;
//   }

//   return true;
// }

function generateMessagge (request) {
  let responseUrl = request.responseURL ? request.responseURL : request.url;
  responseUrl = responseUrl.split('/');
  
  let functionName = responseUrl[responseUrl.length - 1];
  let controllerName = responseUrl[responseUrl.length - 2];
  let statusText = request.statusText ? request.statusText : 'Time out';

  return { title: statusText, description: `.../${ controllerName }/${ functionName }` };
}

function notifyUser (title, text) {
  Vue.prototype.$snotify.error(text, title);
}
