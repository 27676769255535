import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    setting: null,
    readNoteLength: 0
  },
  mutations: {
    store_setSetting (state, passedSetting) {
      state.setting = passedSetting;
    },
    store_setReadNoteLength (state, passedLength) {
      state.readNoteLength = passedLength;
    }
  },
  getters: {
    store_getSetting: state => {
      return state.setting
    },
    store_getEnvironment: state => {
      if (!state.setting) {
        return null;
      }
      return state.setting.environment
    },
    store_getGekkoUrl: state => {
      if (!state.setting) {
        return null;
      }
      return state.setting.gekkoUrl
    },
    store_getJiraUrl: state => {
      if (!state.setting) {
        return null;
      }
      return state.setting.jiraUrl
    },
    store_getConfluenceUrl: state => {
      if (!state.setting) {
        return null;
      }
      return state.setting.confluenceUrl
    },
    store_getZabbixUrl: state => {
      if (!state.setting) {
        return null;
      }
      return state.setting.zabbixUrl
    },
    store_getReadNoteLength: state => {
      if (!state.readNoteLength) {
        return null;
      }
      return state.readNoteLength;
    }
  }
})
